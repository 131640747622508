<template>
     <div class="row layout-top-spacing">
          <div id="tableStriped" class="col-lg-12 col-12 layout-spacing">
               <div class="statbox widget box box-shadow">
                    <div class="widget-header">
                         <div class="row">
                              <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                                   <h4>Vouches</h4>
                              </div>
                         </div>
                    </div>
                    <div class="widget-content widget-content-area">
                         <div class="table-responsive" style="overflow-x:auto!important">
                              <table v-if="scouts.length > 0 && !loading" class="table table-bordered table-striped mb-4">
                                   <thead>
                                        <tr>
                                             <th>Voucher</th>
                                             <th>Candidate</th>
                                             <th>Relationship</th>
                                             <th>Ideal Job</th>
                                             <th>Created at</th>
                                             <!-- <th class="text-center">Action</th> -->
                                        </tr>
                                   </thead>
                                   <tbody>
                                        <template v-for="voucher in this.scouts">
                                             <template v-if="voucher.candidates.length > 0">
                                                  <tr v-for="candidate in voucher.candidates" :key="candidate._id">
                                                       <td class="" style="display:flex">
                                                            <img :src="voucher.picture" style="height:30px;width:auto; " v-if="voucher.picture" />
                                                            <div style="font-weight:600;padding-left:20px;">
                                                                 {{ voucher.name }}<br />
                                                                 {{ voucher.email }} <br />
                                                                 <a target="_blank" :href="voucher.linkedinURL">{{ voucher.linkedinURL }}</a>
                                                            </div>
                                                       </td>
                                                       <td style="color:white;">
                                                            <div>
                                                                 {{ candidate.emoji + " " + candidate.firstName + " " + candidate.lastName }} <br />

                                                                 {{ candidate.email }} <br />
                                                                 <a :href="candidate.linkedinURL">{{ candidate.linkedinURL }}</a>
                                                            </div>
                                                       </td>
                                                       <td>
                                                            <span class="tags" v-for="relationship in candidate.relationship" :key="relationship">{{
                                                                 relationship
                                                            }}</span>
                                                       </td>
                                                       <td>
                                                            {{ candidate.idealJob }}
                                                       </td>
                                                       <td>
                                                            {{ candidate.createdAt | timeago }}
                                                       </td>

                                                       <!--    <td>
                                                            <span
                                                                 class="tag is-success "
                                                                 style="display:inline-block;margin-right:3px;padding-top:3px;font-weight:600"
                                                                 v-for="tag in job.tags"
                                                                 :key="tag"
                                                                 >{{ tag }}</span
                                                            >
                                                       </td>
                                                       <td>
                                                            <span
                                                                 class="tag is-success "
                                                                 style="display:inline-block;margin-right:3px;padding-top:3px;font-weight:600"
                                                                 v-for="tag in job.skills"
                                                                 :key="tag"
                                                                 >{{ tag }}</span
                                                            >
                                                       </td>
                                                       <td></td> -->
                                                  </tr></template
                                             >
                                        </template>
                                   </tbody>
                              </table>
                         </div>
                    </div>
               </div>

               <download-csv :data="downloadData">
                    Download Data
               </download-csv>
          </div>
     </div>
</template>

<script>
     import JsonCSV from "vue-json-csv";

     import "../assets/css/forms/theme-checkbox-radio.css";
     import "../assets/css/tables/table-basic.css";

     import apis from "../apis/data/apis.js";
     export default {
          name: "VOUCHES",
          data() {
               return {
                    scouts: [],
                    loading: true,
                    vouchCount: 0,
                    downloadData: [],
               };
          },
          methods: {
               alertError(errorMessage) {
                    this.loading = false;
                    this.$buefy.toast.open({
                         message: errorMessage,
                         position: "is-bottom",
                         type: "is-danger",
                    });
               },
               getCount() {
                    this.vouchCount++;
                    // console.log(this.vouchCount)
                    return this.vouchCount;
               },

               getDownloadData() {
                    for (var i = 0; i < this.scouts.length; i++) {
                         let voucher = this.scouts[i];

                         for (var j = 0; j < voucher.candidates.length; j++) {
                              let candidate = voucher.candidates[j];
                              this.downloadData.push({
                                   "Candidate Name": candidate.firstName + " " + candidate.lastName,
                                   "Candidate Email": candidate.email,
                                   "Candidate Linkedin": candidate.linkedinURL,
                                   "Ideal Job": candidate.idealJob,
                                   Relationship: candidate.relationship,
                                   "Voucher Name": voucher.name,
                                   "Voucher Email": voucher.email,
                                   "Voucher Linkedin": voucher.linkedinURL,
                              });
                         }
                    }
               },
          },
          components: {
               downloadCsv: JsonCSV,
          },

          filters: {
               timeago(previous) {
                    let current = new Date();
                    previous = new Date(previous);

                    var msPerMinute = 60 * 1000;
                    var msPerHour = msPerMinute * 60;
                    var msPerDay = msPerHour * 24;
                    var msPerMonth = msPerDay * 30;
                    var msPerYear = msPerDay * 365;

                    var elapsed = current - previous;

                    if (elapsed < msPerMinute) {
                         return Math.round(elapsed / 1000) + " seconds ago";
                    } else if (elapsed < msPerHour) {
                         return Math.round(elapsed / msPerMinute) + " minutes ago";
                    } else if (elapsed < msPerDay) {
                         return Math.round(elapsed / msPerHour) + " hours ago";
                    } else if (elapsed < msPerMonth) {
                         return "" + Math.round(elapsed / msPerDay) + " days ago";
                    } else if (elapsed < msPerYear) {
                         return "" + Math.round(elapsed / msPerMonth) + " months ago";
                    } else {
                         return "" + Math.round(elapsed / msPerYear) + " years ago";
                    }
               },
          },

          async created() {
               try {
                    let response = await apis.getVouches();
                    // console.log(response);
                    if (!response || response.status !== 200) {
                         this.alertError("Error creating Job");
                    } else {
                         this.scouts = response.data.scouts;
                         // console.log(this.scouts);
                         this.loading = false;
                    }

                    this.getDownloadData();
               } catch (e) {
                    if (e.response) {
                         console.log(e.response.data);
                    }
                    this.alertError("Error getting vouches");
                    this.loading = false;
               }
          },
     };
</script>
