<template>
     <div class="container" style="margin:0">
      


      




          <div class="container" style="margin:0">
               <div class="row layout-top-spacing">
                    <div id="tableStriped" class="col-lg-12 col-12 layout-spacing">
                         <div class="statbox widget box box-shadow">
                              <div class="widget-header">
                                   <div class="row">
                                        <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                                             <h4>All companies</h4>
                                        </div>
                                   </div>
                              </div>
                              <div class="widget-content widget-content-area">
                                   <div class="table-responsive" style="overflow-x:hidden!important">
                                        <table v-if="companies.length > 0 && !loading" class="table table-bordered table-striped mb-4">
                                             <thead>
                                                  <tr>
                                                       <th class="text-center">Logo</th>
                                                       <th>Name</th>
                                                       <th class="">Id</th>

                                                       <th class="text-center">Action</th>
                                                  </tr>
                                             </thead>
                                             <tbody>
                                                  <tr v-for="company in companies" :key="company._id"> 
                                                        <td class=" text-center">
                                                            <img :src="company.imageSmall" style="height:50px;width:auto" />
                                                       </td>
                                                       <td style="color:white;">
                                                            {{ company.name }}
                                                       </td>
                                                       <td>
                                                            <span>{{ company._id }}</span>
                                                       </td>
                                                     
                                                       <td></td>
                                                  </tr>
                                             </tbody>
                                        </table>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
     import '../assets/css/forms/theme-checkbox-radio.css';
     import '../assets/css/tables/table-basic.css';

     import apis from '../apis/data/apis.js';
     export default {
          name: 'COMPANIES',
          data() {
               return { companies: [], loading: true };
          },
          methods: {
               alertError(errorMessage) {
                    this.loading = false;
                    this.$buefy.toast.open({
                         message: errorMessage,
                         position: 'is-bottom',
                         type: 'is-danger',
                    });
               },
          },

          async created() {
               try {
                    let response = await apis.getAllCompanies();
                    console.log(response);
                    if (!response || response.status !== 200) {
                         this.alertError('Error creating Job');
                    } else {
                         this.companies = response.data;
                         this.loading = false;
                    }
               } catch (e) {
                    this.alertError('Error creating Job');
                    this.loading = false;
               }
          },
     };
</script>
