import Vue from 'vue';
import App from './App.vue';
import router from './router/router';
import store from './store/store';

import Buefy from 'buefy';
import 'buefy/dist/buefy.css';

Vue.use(Buefy);

import 'vue-multiselect/dist/vue-multiselect.min.css';
import './assets/bootstrap/css/bootstrap.min.css';
import './assets/css/plugins.css';



import Multiselect from 'vue-multiselect';

// register globally
Vue.component('multiselect', Multiselect);

import './assets/css/overrides.scss';
Vue.config.productionTip = false;

new Vue({
     router,
     store,
     render: (h) => h(App),
}).$mount('#app');
