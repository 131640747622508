import config from './config.js';
import axios from 'axios';
export default {
     //create user

     async storageController(data) {
          return await config().storageController.post('', data);
     },

     async S3Uploader(url, data) {
          return await axios.put(url, data);
     },

     async createCompany(data) {
          return await config().base.post(`company/create`, data);
     },
     async getAllCompanies() {
          return await config().base.get(`company`);
     },

     async createHR(data) {
          return await config().base.post(`hr/create`, data);
     },
     async createJob(data) {
          return await config().base.post(`jobs/create`, data);
     },
     async getAllJobs() {
          return await config().base.put(`jobs`, { active: false });
     },
     async searchJob() {
          return await config().base.get(`jobs`, data);
     },

     async createJobTags(data) {
          return await config().base.post(`tags/create`, data);
     },
     async getJobTags() {
          return await config().base.get(`tags`);
     },
     async createSkillTags(data) {
          console.log('test', data);
          return await config().base.post(`tags/skills/create`, data);
     },

     async getSkillTags() {
          return await config().base.get(`tags/skills`);
     },

     async createLocationTags(data) {
          return await config().base.post(`tags/locations/create`, data);
     },

     async getLocationTags() {
          return await config().base.get(`tags/locations`);
     },
     async createCompanyTags(data) {
          return await config().base.post(`tags/company/create`, data);
     },

     async getCompanyTags() {
          return await config().base.get(`tags/company`);
     },
      async getVouches(page) {
         
          return await config().earn.get(`/admin/vouches?page=1&pageSize=10000`);
     },
};
