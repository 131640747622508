<template>
  
               <div class="row layout-top-spacing">
                    <div id="tableStriped" class="col-lg-12 col-12 layout-spacing">
                         <div class="statbox widget box box-shadow">
                              <div class="widget-header">
                                   <div class="row">
                                        <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                                             <h4>Vouches</h4>
                                        </div>
                                   </div>
                              </div>
                              <div class="widget-content widget-content-area">
                                   <div class="table-responsive" style="overflow-x:auto!important">
                                        
                                        <table v-if="scouts.length > 0 && !loading" class="table table-bordered table-striped mb-4">
                                             <thead>
                                                  <tr> <th></th>
                                                       <th>Scout</th>
                                                       <th>Email</th>
                                                       <th>Linkedin</th>
                                                       <th>Vouches</th>
                                              
                                                  </tr>
                                             </thead>
                                             <tbody>

                                                  <template v-for=" (voucher , index) in this.scouts" >
  
                                               
                                              
                                                  <tr :key="voucher._id" > 

                                                       <td>{{index + 1}}</td>
                                                   
                                                       <td class="" style="display:flex">
                                                            <img :src="voucher.picture" style="height:30px;width:auto; "  v-if="voucher.picture"/>
                                                           <div style="font-weight:600;padding-left:20px;">{{ voucher.name }}
                                                  
                                                           </div> 
                                                       </td>
                                                     
                                                   <td>
                                                       {{ voucher.email }} 
                                                   </td>

                                                   <td>
                                                             <a target="_blank" :href="voucher.linkedinURL">{{voucher.linkedinURL}}</a>
                                                   </td>

                                                   <td>

                                                        {{voucher.candidates.length}}
                                                   </td>

                                                      <!--    <td>
                                                            <span
                                                                 class="tag is-success "
                                                                 style="display:inline-block;margin-right:3px;padding-top:3px;font-weight:600"
                                                                 v-for="tag in job.tags"
                                                                 :key="tag"
                                                                 >{{ tag }}</span
                                                            >
                                                       </td>
                                                       <td>
                                                            <span
                                                                 class="tag is-success "
                                                                 style="display:inline-block;margin-right:3px;padding-top:3px;font-weight:600"
                                                                 v-for="tag in job.skills"
                                                                 :key="tag"
                                                                 >{{ tag }}</span
                                                            >
                                                       </td>
                                                       <td></td> -->
                                                  </tr></template>
                                             
                                             </tbody>
                                        </table>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
  
</template>

<script>
     import '../assets/css/forms/theme-checkbox-radio.css';
     import '../assets/css/tables/table-basic.css';

     import apis from '../apis/data/apis.js';
     export default {
          name: 'VOUCHES',
          data() {
               return { scouts: [], loading: true ,vouchCount:0
            
               
               };

          },
          methods: {
               alertError(errorMessage) {
                    this.loading = false;
                    this.$buefy.toast.open({
                         message: errorMessage,
                         position: 'is-bottom',
                         type: 'is-danger',
                    });
               },
               getCount(){
                    this.vouchCount++
                    // console.log(this.vouchCount)
                    return this.vouchCount
}
          },

          filters:{
timeago(previous) {

let current = new Date();
 previous = new Date(previous);

    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;

    if (elapsed < msPerMinute) {
         return Math.round(elapsed/1000) + ' seconds ago';   
    }

    else if (elapsed < msPerHour) {
         return Math.round(elapsed/msPerMinute) + ' minutes ago';   
    }

    else if (elapsed < msPerDay ) {
         return Math.round(elapsed/msPerHour ) + ' hours ago';   
    }

    else if (elapsed < msPerMonth) {
        return '' + Math.round(elapsed/msPerDay) + ' days ago';   
    }

    else if (elapsed < msPerYear) {
        return '' + Math.round(elapsed/msPerMonth) + ' months ago';   
    }

    else {
        return '' + Math.round(elapsed/msPerYear ) + ' years ago';   
    }
}
               },

          async created() {
               try {
                    let response = await apis.getVouches();
                    // console.log(response);
                    if (!response || response.status !== 200) {
                         this.alertError('Error creating Job');
                    } else {
                         this.scouts = response.data.scouts;
                         // console.log(this.scouts);
                         this.loading = false;
                    }
               } catch (e) {
                    if (e.response) {
                         // console.log(e.response.data);
                    }
                    this.alertError('Error getting vouches');
                    this.loading = false;
               }
          },
     };
</script>
