<template>
     <div>
          <div class="row">
               <div id="flStackForm" class="col-lg-6 layout-spacing layout-top-spacing">
                    <div class="statbox widget box box-shadow">
                         <div class="widget-header">
                              <div class="row">
                                   <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                                        <h4>Add company</h4>
                                   </div>
                              </div>
                         </div>
                         <div class="widget-content widget-content-area">
                              <div>
                                   <div class="form-group mb-3">
                                        <label>Company Name</label>
                                        <input v-model="companyname" type="text" class="form-control" placeholder="" />
                                   </div>
                                   <div class="form-group mb-3">
                                        <label>Number of Employees</label>

                                        <multiselect v-model="noOfEmployees" :options="noOfEmployeesOptions"></multiselect>
                                   </div>
                                   <div class="form-group mb-3">
                                        <label>Company Description</label>
                                        <!-- <textarea
                                            
                                             class="form-control"
                                             id="exampleFormControlTextarea1"
                                             rows="5"
                                             spellcheck="false"
                                        ></textarea> -->

                                        <vue-editor v-model="desc"></vue-editor>
                                        <div v-html="desc" class="htmlRender"></div>
                                   </div>
                                   <div class="form-group mb-3">
                                        <label>Tags</label>
                                        <multiselect
                                             v-model="tags"
                                             tag-placeholder="Add this as new tag"
                                             placeholder="Search or add a tag"
                                             :options="tagsOptions"
                                             :multiple="true"
                                             :taggable="false"
                                             @tag="addTag"
                                        ></multiselect>
                                   </div>
                                   <div class="row ">
                                        <div class=" col-6 form-group ">
                                             <label>Image big</label>
                                             <file-pond
                                                  name="test"
                                                  ref="pondbig"
                                                  label-idle="Select or Drop file"
                                                  v-bind:allow-multiple="false"
                                                  accepted-file-types="image/png"
                                                  v-on:updatefiles="handleOnupdatefilesBig"
                                             />
                                        </div>
                                        <div class="col-6 form-group ">
                                             <label>Image small</label>
                                             <file-pond
                                                  name="test"
                                                  ref="pondsmall"
                                                  label-idle="Select or Drop file"
                                                  v-bind:allow-multiple="false"
                                                  accepted-file-types="image/png"
                                                  v-on:updatefiles="handleOnupdatefilesSmall"
                                             />
                                        </div>
                                   </div>

                                   <div class="mb-4 ">
                                        <button @click="submitform" class="btn btn-primary" v-if="!loading">Submit</button>
                                        <div class="spinner-grow text-primary ml-4 mb-1 align-self-center loader-sm " v-else></div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
     import apis from '../apis/data/apis.js';

     import vueFilePond from 'vue-filepond';
     import 'filepond/dist/filepond.min.css';
     import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
     import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
     import { VueEditor } from 'vue2-editor';

     export default {
          name: 'Home',
          data() {
               return {
                    loading: false,
                    companyname: '',
                    desc: '',
                    imageBig: undefined,
                    imageSmall: undefined,
                    noOfEmployees: undefined,

                    tags: [],

                    tagsOptions: [],
                    noOfEmployeesOptions: ['1-10', '11-50', '51-200', '201-500', '501-1000', '1001-5000', '5000+'],
               };
          },

          components: {
               FilePond: vueFilePond(FilePondPluginImagePreview),
               VueEditor,
          },

          async created() {
               await this.getTags();
          },
          methods: {
               addTag(newTag) {
                    this.tags.push(newTag);
               },

               submitform() {
                    this.upload();
               },
               handleOnupdatefilesSmall(x) {
                    this.imageSmall = x[0].file;
               },
               handleOnupdatefilesBig(x) {
                    this.imageBig = x[0].file;
               },

               async upload() {
                    this.loading = true;

                    let smallUploadURL = await this.uploadController(this.imageSmall.name);
                    let bigUploadURL = await this.uploadController(this.imageBig.name);

                    if ((await this.uploadimage(bigUploadURL, this.imageBig)) && (await this.uploadimage(smallUploadURL, this.imageSmall))) {
                         this.createCompany(this.imageSmall.name, this.imageBig.name);
                    }

                    this.loading = false;
               },

               async uploadController(imageName) {
                    try {
                         let response = await apis.storageController({
                              type: 'PUT',
                              bucket: 'vouch-company-details',
                              fileName: `${this.companyname}/${imageName}`,
                         });
                         console.log('controller', response);
                         if (!response || response.status !== 200) {
                              console.log(response);
                              this.alertError('Error @ storage controller: ' + imageName);
                         } else {
                              return response.data.url;
                         }

                         //  await this.send();
                    } catch (e) {
                         this.alertError('Error @ storage controller: ' + imageName);
                    }
               },

               async uploadimage(url, image) {
                    try {
                         console.log('url', url);
                         let response = await apis.S3Uploader(url, image);

                         if (!response || response.status !== 200) {
                              this.alertError('Error uploading image ' + image.name);
                              return false;
                         } else {
                              return true;
                         }

                         //  await this.send();
                    } catch (e) {
                         this.alertError('Error uploading image ' + image.name);
                         return false;
                    }
               },

               async createCompany(smallName, bigName) {
                    try {
                         let response = await apis.createCompany({
                              name: this.companyname,
                              tags: this.tags,
                              employees: this.noOfEmployees,
                              imageSmall: `https://vouch-company-details.s3.eu-central-1.amazonaws.com/${this.companyname}/${smallName}`,
                              imageBig: `https://vouch-company-details.s3.eu-central-1.amazonaws.com/${this.companyname}/${bigName}`,
                              description: this.desc,
                         });

                         if (!response || response.status === 409) {
                              this.alertError('Company already exists');
                         } else if (!response || response.status !== 201) {
                              this.alertError('Error submitting');
                         } else {
                              this.$buefy.toast.open({
                                   message: 'Submitted',
                                   position: 'is-bottom',
                                   type: 'is-success',
                              });

                              this.resetInputs();
                         }

                         //  await this.send();
                    } catch (e) {
                         this.alertError('Error submitting');
                         return false;
                    }
               },

               async getTags() {
                    try {
                         let response = await apis.getCompanyTags();
                         console.log(response.data.data);

                         if (!response || response.status !== 200) {
                              this.alertError('Error fetching Company tags');
                         } else {
                              this.tagsOptions = response.data.data;
                         }
                    } catch (e) {
                         if (e.response) {
                              console.log(e.response.data);
                              console.log(e.response.status);
                              console.log(e.response.headers);
                         }
                         this.alertError('Error fetching Company tags');
                    }
               },

               alertError(errorMessage) {
                    this.loading = false;
                    this.$buefy.toast.open({
                         message: errorMessage,
                         position: 'is-bottom',
                         type: 'is-danger',
                    });
               },

               resetInputs() {
                    this.companyname = '';
                    this.desc = '';
                    this.tags = [];
                    this.noOfEmployees = 1;
                    this.imageSmall = undefined;
                    this.imageBig = undefined;
                    this.$refs.pondbig.removeFile();
                    this.$refs.pondsmall.removeFile();
               },
          },
     };
</script>
