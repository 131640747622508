<template>
  
               <div class="row layout-top-spacing">
                    <div id="tableStriped" class="col-lg-12 col-12 layout-spacing">
                         <div class="statbox widget box box-shadow">
                              <div class="widget-header">
                                   <div class="row">
                                        <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                                             <h4>Jobs</h4>
                                        </div>
                                   </div>
                              </div>
                              <div class="widget-content widget-content-area">
                                   <div class="table-responsive" style="overflow-x:hidden!important">
                                        <table v-if="jobs.length > 0 && !loading" class="table table-bordered table-striped mb-4">
                                             <thead>
                                                  <tr>
                                                       <th>Company</th>
                                                       <th>Title</th>
                                                       <th>Type</th>
                                                       <th>Seniority</th>
                                                       <th>Tags</th>
                                                       <th>Skills</th>

                                                       <th class="text-center">Action</th>
                                                  </tr>
                                             </thead>
                                             <tbody>
                                                  <tr v-for="job in jobs" :key="job._id">
                                                       <td class="">
                                                            <img :src="job.imageSmall" style="height:30px;width:auto" />
                                                           <span style="font-weight:600;padding-left:30px">{{ job.companyName }}</span> 
                                                       </td>
                                                       <td style="color:white;">
                                                            {{ job.title }}
                                                       </td>
                                                       <td>
                                                            {{ job.type }}
                                                       </td>
                                                       <td>
                                                            {{ job.seniority }}
                                                       </td>
                                                       <td>
                                                            <span
                                                                 class="tag is-success "
                                                                 style="display:inline-block;margin-right:3px;padding-top:3px;font-weight:600"
                                                                 v-for="tag in job.tags"
                                                                 :key="tag"
                                                                 >{{ tag }}</span
                                                            >
                                                       </td>
                                                       <td>
                                                            <span
                                                                 class="tag is-success "
                                                                 style="display:inline-block;margin-right:3px;padding-top:3px;font-weight:600"
                                                                 v-for="tag in job.skills"
                                                                 :key="tag"
                                                                 >{{ tag }}</span
                                                            >
                                                       </td>
                                                       <td></td>
                                                  </tr>
                                             </tbody>
                                        </table>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
  
</template>

<script>
     import '../assets/css/forms/theme-checkbox-radio.css';
     import '../assets/css/tables/table-basic.css';

     import apis from '../apis/data/apis.js';
     export default {
          name: 'JOBS',
          data() {
               return { jobs: [], loading: true };
          },
          methods: {
               alertError(errorMessage) {
                    this.loading = false;
                    this.$buefy.toast.open({
                         message: errorMessage,
                         position: 'is-bottom',
                         type: 'is-danger',
                    });
               },
          },

          async created() {
               try {
                    let response = await apis.getAllJobs();
                    console.log(response);
                    if (!response || response.status !== 200) {
                         this.alertError('Error creating Job');
                    } else {
                         this.jobs = response.data.data;
                         console.log(this.jobs);
                         this.loading = false;
                    }
               } catch (e) {
                    if (e.response) {
                         console.log(e.response.data);
                    }
                    this.alertError('Error getting Jobs');
                    this.loading = false;
               }
          },
     };
</script>
