<template>
     <div class="row">
          <div id="createjob" class="col-lg-6 layout-spacing layout-top-spacing">
               <div class="statbox widget box box-shadow">
                    <div class="widget-header">
                         <div class="row">
                              <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                                   <h4>Create Job</h4>
                              </div>
                         </div>
                    </div>
                    <div class="widget-content widget-content-area">
                         <div>
                              <div class="form-group mb-3">
                                   <label>Company</label>
                                   <multiselect v-model="company" :options="companyOptions" label="name" track-by="_id"></multiselect>
                              </div>
                              <div class="form-group mb-3">
                                   <label>Title</label>
                                   <input v-model="jobTitle" type="text" class="form-control" placeholder="" />
                              </div>
                              <div class="row mb-3">
                                   <div class=" col-6 form-group ">
                                        <label>Type</label>
                                        <multiselect v-model="jobType" :options="jobTypeOptions"></multiselect>
                                   </div>
                                   <div class="col-6 form-group ">
                                        <label>Remote Friendly</label>
                                        <multiselect v-model="remoteFriendly" :options="remoteFriendlyOptions"></multiselect>
                                   </div>
                              </div>
                              <div class="row mb-3">
                                   <div class=" col-6 form-group ">
                                        <label>Seniority</label>
                                        <multiselect v-model="seniority" :options="seniorityOptions"></multiselect>
                                   </div>
                                   <div class="col-6 form-group ">
                                        <label>Not Disclosed</label>
                                        <multiselect v-model="notDisclosed" :options="notDisclosedOptions"></multiselect>
                                   </div>
                              </div>
                              <div class="row mb-3">
                                   <div class=" col-6 form-group ">
                                        <label>Min Salary</label>
                                        <input class="form-control" type="number" v-model="minSalary" min="0" />
                                   </div>
                                   <div class="col-6 form-group ">
                                        <label>Max Salary</label>
                                        <input class="form-control" type="number" v-model="maxSalary" min="0" />
                                   </div>
                              </div>

                              <div class="row mb-3">
                                   <div class=" col-6 form-group ">
                                        <label>Experience</label>
                                        <input class="form-control" type="number" v-model="experience" min="0" />
                                   </div>
                                   <div class="col-6 form-group ">
                                        <label>Currency</label>
                                        <multiselect v-model="currency" :options="currencyOptions"></multiselect>
                                   </div>
                              </div>
                              <div class="form-group mb-3">
                                   <label>Skills</label>
                                   <multiselect
                                        v-model="skills"
                                        tag-placeholder="Add this as new skill"
                                        placeholder="Search or add a skill"
                                        :options="skillsOptions"
                                        :multiple="true"
                                        :taggable="false"
                                        @tag="addSkill"
                                   ></multiselect>
                              </div>
                              <div class="form-group mb-3">
                                   <label>Tags</label>
                                   <multiselect
                                        v-model="tags"
                                        tag-placeholder="Add this as new tag"
                                        placeholder="Search or add a tag"
                                        :options="tagOptions"
                                        :multiple="true"
                                        :taggable="false"
                                        @tag="addTag"
                                   ></multiselect>
                              </div>
                              <div class="row mb-3">
                                   <div class=" col-12 form-group ">
                                        <label>Location</label>
                                        <multiselect
                                             v-model="locations"
                                             tag-placeholder="Add loaciton"
                                             placeholder="Search location"
                                             :options="locationOptions"
                                             :multiple="true"
                                             :taggable="false"
                                             @tag="addSkill"
                                        ></multiselect>
                                   </div>
                              </div>
                              <div class="form-group mb-3">
                                   <label>Job Description</label>
                                   <!-- <textarea
                                        v-model="jobDesc"
                                        class="form-control"
                                        id="exampleFormControlTextarea1"
                                        rows="5"
                                        spellcheck="false"
                                   ></textarea> -->

                                   <vue-editor v-model="jobDesc"></vue-editor>

                                   <div v-html="jobDesc" class="htmlRender"></div>
                              </div>
                              <div class="mb-4 mt-4 ">
                                   <button @click="submitform" class="btn btn-primary" v-if="!loading">Submit</button>
                                   <div class="spinner-grow text-primary ml-4 mb-1 align-self-center loader-sm " v-else></div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
     import apis from '../apis/data/apis.js';
     import { VueEditor } from 'vue2-editor';
     export default {
          name: 'Home',

          data() {
               return {
                    minSalary: null,
                    maxSalary: null,
                    loading: false,
                    company: null,
                    jobTitle: '',
                    jobType: null,
                    remoteFriendly: null,
                    seniority: null,
                    notDisclosed: null,
                    experience: null,
                    currency: null,
                    locations: [],
                    skills: [],
                    tags: [],

                    jobDesc: '',

                    skillsOptions: [],
                    locationOptions: [],
                    companyOptions: [],
                    tagOptions: [],

                    currencyOptions: ['USD', 'INR'],
                    jobTypeOptions: ['fullTime', 'partTime', 'internship', 'contract'],
                    remoteFriendlyOptions: ['true', 'false'],
                    seniorityOptions: ['Mid-Senior level', 'Internship', 'Director', 'Entry level', 'Associate', 'Executive'],
                    notDisclosedOptions: ['true', 'false'],
               };
          },

          components: {
               VueEditor,
          },
          async mounted() {
               await this.getCompanyOptions();
               await this.getJobTags();
               await this.getSkillTags();
               await this.getLocationTags();
          },
          methods: {
               async getCompanyOptions() {
                    try {
                         let response = await apis.getAllCompanies();

                         if (!response || response.status !== 200) {
                              this.alertError('Error fetching companies ');
                         } else {
                              this.companyOptions = response.data;
                         }
                    } catch (e) {
                         if (e.response) {
                              console.log(e.response.data);
                              console.log(e.response.status);
                              console.log(e.response.headers);
                         }
                         this.alertError('Error fetching companies ');
                         // this.loading = false;
                    }
               },
               async getLocationTags() {
                    try {
                         let response = await apis.getLocationTags();

                         if (!response || response.status !== 200) {
                              this.alertError('Error fetching location tags ');
                         } else {
                              this.locationOptions = response.data.data;
                         }
                    } catch (e) {
                         if (e.response) {
                              console.log(e.response.data);
                              console.log(e.response.status);
                              console.log(e.response.headers);
                         }
                         this.alertError('Error fetching location tags ');
                         // this.loading = false;
                    }
               },
               async getSkillTags() {
                    try {
                         let response = await apis.getSkillTags();

                         if (!response || response.status !== 200) {
                              this.alertError('Error fetching skill tags ');
                         } else {
                              this.skillsOptions = response.data.data;
                         }
                    } catch (e) {
                         if (e.response) {
                              console.log(e.response.data);
                              console.log(e.response.status);
                              console.log(e.response.headers);
                         }
                         this.alertError('Error fetching skill tags ');
                         // this.loading = false;
                    }
               },
               async getJobTags() {
                    try {
                         let response = await apis.getJobTags();

                         if (!response || response.status !== 200) {
                              this.alertError('Error fetching job tags');
                         } else {
                              this.tagOptions = response.data.data;
                         }
                    } catch (e) {
                         if (e.response) {
                              console.log(e.response.data);
                              console.log(e.response.status);
                              console.log(e.response.headers);
                         }
                         this.alertError('Error fetching job tags');
                    }
               },
               addSkill(newTag) {
                    this.skills.push(newTag);
               },
               addTag(newTag) {
                    this.tags.push(newTag);
               },
               addLocation(newTag) {
                    this.locations.push(newTag);
               },
               submitform() {
                    this.loading = true;

                    this.submit();

                    this.loading = false;
               },
               async submit() {
                    //validate
                    if (this.tags.length == 0) {
                         this.alertError('Please add a job Tag');
                         return;
                    }

                    try {
                         let data = {
                              companyId: this.company._id,
                              title: this.jobTitle,
                              type: this.jobType,
                              remoteFriendly: this.remoteFriendly == 'true',
                              seniority: this.seniority,
                              notDisclosing: this.notDisclosed == 'true',
                              skills: this.skills,
                              tags: this.tags,
                              description: this.jobDesc,
                              locations: this.locations,
                         };

                         if (this.minSalary) {
                              data.minSalary = parseInt(this.minSalary);
                         }
                         if (this.maxSalary) {
                              data.maxSalary = parseInt(this.maxSalary);
                         }
                         if (this.experience) {
                              data.experience = parseInt(this.experience);
                         }
                         if (this.currency) {
                              data.currency = this.currency;
                         }

                         let response = await apis.createJob(data);

                         if (!response || response.status !== 201) {
                              console.log(response);
                              this.alertError('Error creating Job');
                         } else {
                              this.$buefy.toast.open({
                                   message: 'Created',
                                   position: 'is-bottom',
                                   type: 'is-success',
                              });

                              this.resetInputs();
                         }
                    } catch (e) {
                         if (e.response) {
                              this.alertError(e.response.data.message);
                         } else {
                              this.alertError('Error creating Job');
                         }
                    }
               },

               alertError(errorMessage) {
                    this.loading = false;
                    this.$buefy.toast.open({
                         message: errorMessage,
                         position: 'is-bottom',
                         type: 'is-danger',
                    });
               },

               resetInputs() {
                    this.loading = false;
                    this.company = null;
                    this.jobTitle = '';
                    this.jobType = null;
                    this.remoteFriendly = null;
                    this.seniority = null;
                    this.notDisclosed = null;
                    this.skills = [];
                    this.skillstagged = [];
                    this.locations = [];
                    this.tags = [];
                    this.jobDesc = '';
               },
          },
     };
</script>
<style lang="scss" scoped></style>
