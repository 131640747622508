<template >
     <div class="form"  style="background-color:#0e1726">
          <div class="form-container outer">
               <div class="form-form">
                    <div class="form-form-wrap">
                         <div class="form-container">
                              <div class="form-content">
                                   <h1 class="mt-4" style="font-weight:600;font-size:40px">{ vouch.admin }</h1>
                                   <h3 class="mt-4">Sign In</h3>
                                   <p class="">Log in to your account to continue.</p>

                                   <form class="text-left">
                                        <div class="form">
                                             <!-- <div class="form-group mb-3">
                                                  <label>Email</label>
                                                  <input v-model="email" type="email" class="form-control" placeholder="" />
                                             </div> -->

                                             <div class="form-group mb-3">
                                                  <label>Key</label>
                                                  <input v-model="password" type="password" class="form-control" placeholder="" />
                                             </div>
                                             <div class="d-sm-flex justify-content-between mb-5 mt-5">
                                                  <div class="field-wrapper ">
                                                       <button type="submit" class="btn btn-primary" value="" @click="login">Log In</button>
                                                  </div>
                                             </div>
                                        </div>
                                   </form>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
     // @ is an alias to /src
     import '../assets/css/authentication/form-2.css';
     import '../assets/css/forms/theme-checkbox-radio.css';
     import '../assets/css/forms/switches.css';

     export default {
          name: 'Home',
          components: {},
          data() {
               return {
                    email: 'admin@getvouch.me',
                    password: '',
               };
          },


          methods:{

login(){
 

 this.$store.state.secret=this.password;

 localStorage.setItem("secret",this.password)
 this.$router.push({name:'DASHBOARD'})
}
               
     }}
</script>
