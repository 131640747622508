import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/store.js'

Vue.use(VueRouter);

const routes = [
     {
          path: '/',
          name: 'DASHBOARD',
          meta: { breadcrumbs: ['Dashboard'] },
          component: require('../views/DashboardLayout.vue').default,

          children: [
               {
                    path: '',
                    name: 'DASHBOARD',
                    meta: { breadcrumbs: ['Dashboard'] },
                    component: require('../views/Dashboard.vue').default,
               },
               {
                    path: 'companies_create',
                    name: 'CREATE_COMPANIES',
                    meta: { breadcrumbs: ['Companies', 'Create'] },
                    component: require('../views/CreateCompany.vue').default,
               },
                  {
                    path: 'vouches',
                    name: 'VOUCHES',
                    meta: { breadcrumbs: ['Vouch'] },
                    component: require('../views/Vouches.vue').default,
               },
                   {
                    path: 'scouts',
                    name: 'SCOUTS',
                    meta: { breadcrumbs: ['Scouts'] },
                    component: require('../views/Scouts.vue').default,
               },
               {
                    path: 'companies_view',
                    name: 'COMPANIES',
                    meta: { breadcrumbs: ['Companies', 'View'] },
                    component: require('../views/Companies.vue').default,
               },
               {
                    path: 'job_create',
                    name: 'CREATE_JOBS',
                    meta: { breadcrumbs: ['Jobs', 'Create'] },
                    component: require('../views/CreateJobs.vue').default,
               },
               {
                    path: 'job_view',
                    name: 'JOBS',
                    meta: { breadcrumbs: ['Jobs', 'View'] },
                    component: require('../views/Jobs.vue').default,
               },
               {
                    path: 'job_tags',
                    name: 'JOB_TAGS',
                    meta: { breadcrumbs: ['Jobs', 'Tags'] },
                    component: require('../views/JobTags.vue').default,
               },
               {
                    path: 'location_tags',
                    name: 'LOCATION_TAGS',
                    meta: { breadcrumbs: ['Jobs', 'Locations'] },
                    component: require('../views/LocationTags.vue').default,
               },
               {
                    path: 'skill_tags',
                    name: 'SKILL_TAGS',
                    meta: { breadcrumbs: ['Jobs', 'Skills'] },
                    component: require('../views/SkillTags.vue').default,
               },
               {
                    path: 'company_tags',
                    name: 'COMPANY_TAGS',
                    meta: { breadcrumbs: ['Company', 'tags'] },
                    component: require('../views/CompanyTags.vue').default,
               },
               {
                    path: 'hr_create',
                    name: 'CREATE_HR',
                    meta: { breadcrumbs: ['HR', 'Create'] },
                    component: require('../views/CreateHR.vue').default,
               },
               {
                    path: 'hr_view',
                    name: 'HR',
                    meta: { breadcrumbs: ['HR', 'View'] },
                    component: require('../views/HR.vue').default,
               },
          ],
     },
     {
          path: '/login',
          name: 'LOGIN',
          component: require('../views/Login.vue').default,
     },
     // {
     //      path: '*',
     //      name: '404',

     //      component: require('../views/HR.vue').default,
     // },
];

const router = new VueRouter({
     mode: 'history',
     base: process.env.BASE_URL,
     routes,
});


router.beforeEach((to, from, next) => {


    if (!localStorage.secret && to.name !="LOGIN") {
        // You can use store variable here to access globalError or commit mutation 
        next("/login")
    } else {
        next()
    }
})

export default router;
