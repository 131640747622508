<template>
     <div class="">
          <div class="row">
               <div id="flStackForm" class="col-lg-5 layout-spacing layout-top-spacing">
                    <div class="statbox widget box box-shadow">
                         <div class="widget-header">
                              <div class="row">
                                   <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                                        <h4>Add company</h4>
                                   </div>
                              </div>
                         </div>
                         <div class="widget-content widget-content-area">
                              <div>
                                   <div class="form-group mb-3">
                                        <label>Company</label>
                                        <multiselect v-model="companyID" :options="companyOptions"></multiselect>
                                   </div>

                                   <div class="form-group mb-3">
                                        <label>Full Name</label>
                                        <input v-model="fullName" type="text" class="form-control" placeholder="" />
                                   </div>
                                   <div class="form-group mb-3">
                                        <label>Email</label>
                                        <input v-model="email" type="email" class="form-control" placeholder="" />
                                   </div>
                                   <div class="form-group mb-3">
                                        <label>Passoword</label>
                                        <input v-model="password" type="password" class="form-control" placeholder="" />
                                   </div>

                                   <div class="row ">
                                        <div class=" col-12 form-group ">
                                             <label>Profile Image</label>
                                             <file-pond
                                                  name="profilepic"
                                                  ref="imgpond"
                                                  label-idle="Select or Drop file"
                                                  v-bind:allow-multiple="false"
                                                  accepted-file-types="image/png, image,jpg,image/jpeg, image/*"
                                                  v-on:updatefiles="handleOnupdatefile"
                                             />
                                        </div>
                                   </div>

                                   <div class="mb-4 ">
                                        <button @click="submitform" class="btn btn-primary" v-if="!loading">Submit</button>
                                        <div class="spinner-grow text-primary ml-4 mb-1 align-self-center loader-sm " v-else></div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
     import apis from '../apis/data/apis.js';

     import vueFilePond from 'vue-filepond';
     import 'filepond/dist/filepond.min.css';
     import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
     import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
     export default {
          name: 'Home',
          data() {
               return {
                    loading: false,
                    companyID: null,
                    email: null,
                    fullName: null,
                    password: null,
                    profileImage: undefined,

                    companyOptions: [],
               };
          },

          components: {
               FilePond: vueFilePond(FilePondPluginImagePreview),
          },
          methods: {
               submitform() {
                    this.upload();
               },
               handleOnupdatefile(x) {
                    this.profileImage = x[0].file;
               },

               async upload() {
                    this.loading = true;

                    let imageURL = await this.uploadController(this.profileImage.name);

                    if (await this.uploadimage(imageURL, this.imageBig)) {
                         this.createHR(this.profileImage.name);
                    }

                    this.loading = false;
               },

               async uploadController(imageName) {
                    try {
                         let response = await apis.storageController({
                              type: 'PUT',
                              bucket: 'vouch-company-details',
                              fileName: `${this.companyname}/${imageName}`,
                         });
                         console.log('controller', response);
                         if (!response || response.status !== 200) {
                              this.alertError('Error @ storage controller: ' + imageName);
                         } else {
                              return response.data.url;
                         }

                         //  await this.send();
                    } catch (e) {
                         this.alertError('Error @ storage controller: ' + imageName);
                    }
               },

               async uploadimage(url, image) {
                    try {
                         console.log('url', url);
                         let response = await apis.S3Uploader(url, image);

                         if (!response || response.status !== 200) {
                              this.alertError('Error uploading image ' + image.name);
                              return false;
                         } else {
                              return true;
                         }

                         //  await this.send();
                    } catch (e) {
                         this.alertError('Error uploading image ' + image.name);
                         return false;
                    }
               },

               async createHR(imageURL) {
                    try {
                         let response = await apis.createHR({
                              fullName: this.fullName,
                              email: this.email,
                              password: this.password,
                              profileImage: `https://vouch-company-details.s3.eu-central-1.amazonaws.com/HR/${imageURL}`,
                              _companyId: this.companyID,
                         });

                         if (!response || response.status === 409) {
                              this.alertError('HR already exists');
                         } else if (!response || response.status !== 201) {
                              this.alertError('Error submitting');
                         } else {
                              this.$buefy.toast.open({
                                   message: 'Created',
                                   position: 'is-bottom',
                                   type: 'is-success',
                              });

                              this.removefiles();
                         }

                         //  await this.send();
                    } catch (e) {
                         this.alertError('Error submitting');
                         return false;
                    }
               },
               alertError(errorMessage) {
                    this.loading = false;
                    this.$buefy.toast.open({
                         message: errorMessage,
                         position: 'is-bottom',
                         type: 'is-danger',
                    });
               },

               resetInputs() {
                    this.companyID = null;
                    this.email = null;
                    this.fullName = null;
                    this.password = null;
                    this.profileImage = undefined;

                    this.$refs.imgpond.removeFile();
               },
          },
     };
</script>
