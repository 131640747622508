<template>
     <div class="row">
          <div id="createjob" class="col-lg-5 layout-spacing layout-top-spacing">
               <div class="statbox widget box box-shadow">
                    <div class="widget-header">
                         <div class="row">
                              <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                                   <h4>Create Job</h4>
                              </div>
                         </div>
                    </div>
                    <div class="widget-content widget-content-area">
                         <div>
                              <div class="form-group mb-3">
                                   <label>Job Tags</label>
                                   <multiselect
                                        v-model="tags"
                                        tag-placeholder="Add this as new tag"
                                        placeholder="Search or add a tag"
                                        :options="options"
                                        :multiple="true"
                                        :taggable="true"
                                        @tag="addTag"
                                   ></multiselect>
                              </div>
                              <div class="mb-4 mt-4 ">
                                   <button @click="submitform" class="btn btn-primary" v-if="!loading">Submit</button>
                                   <div class="spinner-grow text-primary ml-4 mb-1 align-self-center loader-sm " v-else></div>
                              </div>
                              <div>

                                  <span class="tag is-success "  style="" v-for="tag in alltags" :key="tag">{{tag}}</span>

                              </div>
                              
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
     import apis from '../apis/data/apis.js';
     export default {
          name: 'Home',

          data() {
               return {
                    options: [],
                    loading: false,

                    tags: [],

                    alltags: [],
               };
          },

          async mounted() {
               await this.getTags();
          },
          methods: {
               addTag(newTag) {
                    console.log(newTag);

                    this.tags.push(newTag);
               },
               submitform() {
                    this.loading = true;

                    this.submit();

                    this.loading = false;
               },
               async getTags() {
                    try {
                         let response = await apis.getJobTags();

                         if (!response || response.status !== 200) {
                              this.alertError('Error fetching job tags');
                         } else {
                              this.alltags = response.data.data;
                              console.log(this.alltags);
                         }
                    } catch (e) {
                         if (e.response) {
                              console.log(e.response.data);
                              console.log(e.response.status);
                              console.log(e.response.headers);
                         }
                         this.alertError('Error fetching job tags');
                    }
               },
               async submit() {
                    try {
                         let response = await apis.createJobTags({
                              tags: this.tags,
                         });

                         if (!response || response.status !== 201) {
                              console.log(response);
                              this.alertError('Error creating Job tags');
                         } else {
                              this.$buefy.toast.open({
                                   message: 'Added',
                                   position: 'is-bottom',
                                   type: 'is-success',
                              });

                              this.resetInputs();
                              await this.getTags();
                         }
                    } catch (e) {
                         if (e.response) {
                              console.log(e.response.data.message);
                         }
                         this.alertError('Error creating Job tags');
                    }
               },

               alertError(errorMessage) {
                    this.loading = false;
                    this.$buefy.toast.open({
                         message: errorMessage,
                         position: 'is-bottom',
                         type: 'is-danger',
                    });
               },

               resetInputs() {
                    this.tags = [];
               },
          },
     };
</script>
