import axios from 'axios';
import store from '../../store/store.js'
export default () => {

     console.log("hihi")
     return {
          storageController: axios.create({
               baseURL: 'https://34o5fue5wg.execute-api.ap-south-1.amazonaws.com/default/storageController',
               headers: {
                    //    'Content-Type': 'application/json',
                    //    Authorization: 'Bearer ' + localStorage.tk,
                    // 'Authorization': 'Bearer ' +"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMmE2MWU1YjI1MjBmYTIzNWE2MjFjMDk5ZjNjZTYzMzIyZTFjYTk3MmY2NzgyYmRiMDBmM2EwZTRhYWU5Y2MwMDc4MjRkMDYzOTI2Y2ExNGQiLCJpYXQiOjE1NzM3Mjk0NzksIm5iZiI6MTU3MzcyOTQ3OSwiZXhwIjoxNjA1MzUxODc5LCJzdWIiOiIxMTIiLCJzY29wZXMiOltdfQ.BCSwucuaU7aLK6MyYKM1JOGSTPmW3KuKuDYmMYkHou5myXjlm86jh1Rzk4qSNGBxDciwRCbcKus16y5e-zEZu-KXV-UqiWIek6fPn8PzHm81t1D-8ON0T8NIZ3KhBwH5GiUA8Cw-HuwTQdS394groBdN8nJNCtfFxRi_kRmIbZdGV2mH1czmaxeVUGklO0CvnmtaL_GkpIEm607FpHXYPnXBqLQlhpH038QjCUHRK4b49w6TZmBfUxZCsovGFGSemw61l5tc70mh9ooZLoakSkgTLRGMVyGftQU-WH_bwSU5FM6tfb5_XpDWcBM_e87gfJqg2TAqJTVmDtBeAiXeqHlu5WiFSapLIcPsPeHuI0g8Vi0nmv5chjfbJQjq9ExAQi_8gVaMgNp6v81fW7evthLmRx7UlvLS-_74jnlyxiCG2cJMox9lWK7dc_CqkiDEHa02rp4Sus8QXOcjQC0olLEF9ZpxacC7N4pwC5xLswoK6yudwFoc9wB-frEn0rzTm9VUV0OqsWBlJooHoNOZiYvBIxuQ0umbhmxIKG8NOFjkLAcwXnMGIHr085W5pxyp74RoNOSLMpq0QZr7FfmrVsXtZbcaow2mCMcLMcuob4ARSQy2J8rChRbtC5_Naigm4FNwF8uGMAL1TGh4LOt5KrCPjsakjhCqvqRzCE_B7wI"
               },
          }),

          base: axios.create({
               baseURL: 'https://api.getvouch.me/',
               headers: {

                    // 'x-api-key': '0HqN5zYjDWpHiYXFiFeiAJQaOdX9Papx'
                    //    'Content-Type': 'application/json',
                    //    Authorization: 'Bearer ' + localStorage.tk,
                    // 'Authorization': 'Bearer ' +"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMmE2MWU1YjI1MjBmYTIzNWE2MjFjMDk5ZjNjZTYzMzIyZTFjYTk3MmY2NzgyYmRiMDBmM2EwZTRhYWU5Y2MwMDc4MjRkMDYzOTI2Y2ExNGQiLCJpYXQiOjE1NzM3Mjk0NzksIm5iZiI6MTU3MzcyOTQ3OSwiZXhwIjoxNjA1MzUxODc5LCJzdWIiOiIxMTIiLCJzY29wZXMiOltdfQ.BCSwucuaU7aLK6MyYKM1JOGSTPmW3KuKuDYmMYkHou5myXjlm86jh1Rzk4qSNGBxDciwRCbcKus16y5e-zEZu-KXV-UqiWIek6fPn8PzHm81t1D-8ON0T8NIZ3KhBwH5GiUA8Cw-HuwTQdS394groBdN8nJNCtfFxRi_kRmIbZdGV2mH1czmaxeVUGklO0CvnmtaL_GkpIEm607FpHXYPnXBqLQlhpH038QjCUHRK4b49w6TZmBfUxZCsovGFGSemw61l5tc70mh9ooZLoakSkgTLRGMVyGftQU-WH_bwSU5FM6tfb5_XpDWcBM_e87gfJqg2TAqJTVmDtBeAiXeqHlu5WiFSapLIcPsPeHuI0g8Vi0nmv5chjfbJQjq9ExAQi_8gVaMgNp6v81fW7evthLmRx7UlvLS-_74jnlyxiCG2cJMox9lWK7dc_CqkiDEHa02rp4Sus8QXOcjQC0olLEF9ZpxacC7N4pwC5xLswoK6yudwFoc9wB-frEn0rzTm9VUV0OqsWBlJooHoNOZiYvBIxuQ0umbhmxIKG8NOFjkLAcwXnMGIHr085W5pxyp74RoNOSLMpq0QZr7FfmrVsXtZbcaow2mCMcLMcuob4ARSQy2J8rChRbtC5_Naigm4FNwF8uGMAL1TGh4LOt5KrCPjsakjhCqvqRzCE_B7wI"
               },
          }),
        
          earn:axios.create({
               baseURL: 'https://prod.getvouch.me/',
               headers: {

                    'x-api-key': localStorage.secret
                    //    'Content-Type': 'application/json',
                    //    Authorization: 'Bearer ' + localStorage.tk,
                    // 'Authorization': 'Bearer ' +"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMmE2MWU1YjI1MjBmYTIzNWE2MjFjMDk5ZjNjZTYzMzIyZTFjYTk3MmY2NzgyYmRiMDBmM2EwZTRhYWU5Y2MwMDc4MjRkMDYzOTI2Y2ExNGQiLCJpYXQiOjE1NzM3Mjk0NzksIm5iZiI6MTU3MzcyOTQ3OSwiZXhwIjoxNjA1MzUxODc5LCJzdWIiOiIxMTIiLCJzY29wZXMiOltdfQ.BCSwucuaU7aLK6MyYKM1JOGSTPmW3KuKuDYmMYkHou5myXjlm86jh1Rzk4qSNGBxDciwRCbcKus16y5e-zEZu-KXV-UqiWIek6fPn8PzHm81t1D-8ON0T8NIZ3KhBwH5GiUA8Cw-HuwTQdS394groBdN8nJNCtfFxRi_kRmIbZdGV2mH1czmaxeVUGklO0CvnmtaL_GkpIEm607FpHXYPnXBqLQlhpH038QjCUHRK4b49w6TZmBfUxZCsovGFGSemw61l5tc70mh9ooZLoakSkgTLRGMVyGftQU-WH_bwSU5FM6tfb5_XpDWcBM_e87gfJqg2TAqJTVmDtBeAiXeqHlu5WiFSapLIcPsPeHuI0g8Vi0nmv5chjfbJQjq9ExAQi_8gVaMgNp6v81fW7evthLmRx7UlvLS-_74jnlyxiCG2cJMox9lWK7dc_CqkiDEHa02rp4Sus8QXOcjQC0olLEF9ZpxacC7N4pwC5xLswoK6yudwFoc9wB-frEn0rzTm9VUV0OqsWBlJooHoNOZiYvBIxuQ0umbhmxIKG8NOFjkLAcwXnMGIHr085W5pxyp74RoNOSLMpq0QZr7FfmrVsXtZbcaow2mCMcLMcuob4ARSQy2J8rChRbtC5_Naigm4FNwF8uGMAL1TGh4LOt5KrCPjsakjhCqvqRzCE_B7wI"
               },
          }),



     };
};
