<template>
     <div class="row layout-top-spacing">
          <div class="col-12" style="font-size:80px;text-align:center;margin-top:20px;display:block;">{ vouch.admin }</div>

          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing mt-5">
               <div class="row widget-statistic">
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12  mt-4">
                         <div class="widget widget-one_hybrid widget-followers">
                              <div class="widget-heading">
                                   <div class="w-icon">
                                        <svg
                                             xmlns="http://www.w3.org/2000/svg"
                                             width="24"
                                             height="24"
                                             viewBox="0 0 24 24"
                                             fill="none"
                                             stroke="currentColor"
                                             stroke-width="2"
                                             stroke-linecap="round"
                                             stroke-linejoin="round"
                                             class="feather feather-users"
                                        >
                                             <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                             <circle cx="9" cy="7" r="4"></circle>
                                             <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                             <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                        </svg>
                                   </div>
                                   <p class="w-value">31003</p>
                                   <h5 class="">Users</h5>
                              </div>
                         </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12  mt-4">
                         <div class="widget widget-one_hybrid widget-followers">
                              <div class="widget-heading">
                                   <div class="w-icon">
                                        <svg
                                             viewBox="0 0 24 24"
                                             width="24"
                                             height="24"
                                             stroke="currentColor"
                                             stroke-width="2"
                                             fill="none"
                                             stroke-linecap="round"
                                             stroke-linejoin="round"
                                             class="css-i6dzq1"
                                        >
                                             <circle cx="12" cy="8" r="7"></circle>
                                             <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline>
                                        </svg>
                                   </div>
                                   <p class="w-value">1900</p>
                                   <h5 class="">Vouches</h5>
                              </div>
                         </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12  mt-4">
                         <div class="widget widget-one_hybrid widget-followers">
                              <div class="widget-heading">
                                   <div class="w-icon">
                                        <svg
                                             viewBox="0 0 24 24"
                                             width="24"
                                             height="24"
                                             stroke="currentColor"
                                             stroke-width="2"
                                             fill="none"
                                             stroke-linecap="round"
                                             stroke-linejoin="round"
                                             class="css-i6dzq1"
                                        >
                                             <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
                                             <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
                                        </svg>
                                   </div>
                                   <p class="w-value">1800</p>
                                   <h5 class="">Jobs</h5>
                              </div>
                         </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12  mt-4">
                         <div class="widget widget-one_hybrid widget-followers">
                              <div class="widget-heading">
                                   <div class="w-icon">
                                        <svg
                                             viewBox="0 0 24 24"
                                             width="24"
                                             height="24"
                                             stroke="currentColor"
                                             stroke-width="2"
                                             fill="none"
                                             stroke-linecap="round"
                                             stroke-linejoin="round"
                                             class="css-i6dzq1"
                                        >
                                             <polyline points="21 8 21 21 3 21 3 8"></polyline>
                                             <rect x="1" y="3" width="22" height="5"></rect>
                                             <line x1="10" y1="12" x2="14" y2="12"></line>
                                        </svg>
                                   </div>
                                   <p class="w-value">50</p>
                                   <h5 class="">Companies</h5>
                              </div>
                         </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 mt-4">
                         <div class="widget widget-one_hybrid widget-followers">
                              <div class="widget-heading">
                                   <div class="w-icon">
                                        <svg
                                             xmlns="http://www.w3.org/2000/svg"
                                             width="24"
                                             height="24"
                                             viewBox="0 0 24 24"
                                             fill="none"
                                             stroke="currentColor"
                                             stroke-width="2"
                                             stroke-linecap="round"
                                             stroke-linejoin="round"
                                             class="feather feather-message-circle"
                                        >
                                             <path
                                                  d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"
                                             ></path>
                                        </svg>
                                   </div>
                                   <p class="w-value">50</p>
                                   <h5 class="">Lorem</h5>
                              </div>
                         </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 mt-4">
                         <div class="widget widget-one_hybrid widget-followers">
                              <div class="widget-heading">
                                   <div class="w-icon">
                                        <svg
                                             xmlns="http://www.w3.org/2000/svg"
                                             width="24"
                                             height="24"
                                             viewBox="0 0 24 24"
                                             fill="none"
                                             stroke="currentColor"
                                             stroke-width="2"
                                             stroke-linecap="round"
                                             stroke-linejoin="round"
                                             class="feather feather-link"
                                        >
                                             <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
                                             <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
                                        </svg>
                                   </div>
                                   <p class="w-value">50</p>
                                   <h5 class="">Vouch pages</h5>
                              </div>
                         </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 mt-4">
                         <div class="widget widget-one_hybrid widget-followers">
                              <div class="widget-heading">
                                   <div class="w-icon">
                                        <svg
                                             viewBox="0 0 24 24"
                                             width="24"
                                             height="24"
                                             stroke="currentColor"
                                             stroke-width="2"
                                             fill="none"
                                             stroke-linecap="round"
                                             stroke-linejoin="round"
                                             class="css-i6dzq1"
                                        >
                                             <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                             <circle cx="8.5" cy="7" r="4"></circle>
                                             <line x1="20" y1="8" x2="20" y2="14"></line>
                                             <line x1="23" y1="11" x2="17" y2="11"></line>
                                        </svg>
                                   </div>
                                   <p class="w-value">50</p>
                                   <h5 class="">Per day sign-up</h5>
                              </div>
                         </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 mt-4">
                         <div class="widget widget-one_hybrid widget-followers">
                              <div class="widget-heading">
                                   <div class="w-icon">
                                        <svg
                                             viewBox="0 0 24 24"
                                             width="24"
                                             height="24"
                                             stroke="currentColor"
                                             stroke-width="2"
                                             fill="none"
                                             stroke-linecap="round"
                                             stroke-linejoin="round"
                                             class="css-i6dzq1"
                                        >
                                             <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
                                        </svg>
                                   </div>
                                   <p class="w-value">50</p>
                                   <h5 class="">Page visits per day</h5>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>
<style type="text/css">
     .apexcharts-legend {
          display: flex;
          overflow: auto;
          padding: 0 10px;
     }

     .apexcharts-legend.position-bottom,
     .apexcharts-legend.position-top {
          flex-wrap: wrap;
     }
     .apexcharts-legend.position-right,
     .apexcharts-legend.position-left {
          flex-direction: column;
          bottom: 0;
     }

     .apexcharts-legend.position-bottom.left,
     .apexcharts-legend.position-top.left,
     .apexcharts-legend.position-right,
     .apexcharts-legend.position-left {
          justify-content: flex-start;
     }

     .apexcharts-legend.position-bottom.center,
     .apexcharts-legend.position-top.center {
          justify-content: center;
     }

     .apexcharts-legend.position-bottom.right,
     .apexcharts-legend.position-top.right {
          justify-content: flex-end;
     }

     .apexcharts-legend-series {
          cursor: pointer;
          line-height: normal;
     }

     .apexcharts-legend.position-bottom .apexcharts-legend-series,
     .apexcharts-legend.position-top .apexcharts-legend-series {
          display: flex;
          align-items: center;
     }

     .apexcharts-legend-text {
          position: relative;
          font-size: 14px;
     }

     .apexcharts-legend-text *,
     .apexcharts-legend-marker * {
          pointer-events: none;
     }

     .apexcharts-legend-marker {
          position: relative;
          display: inline-block;
          cursor: pointer;
          margin-right: 3px;
     }

     .apexcharts-legend.right .apexcharts-legend-series,
     .apexcharts-legend.left .apexcharts-legend-series {
          display: inline-block;
     }

     .apexcharts-legend-series.no-click {
          cursor: auto;
     }

     .apexcharts-legend .apexcharts-hidden-zero-series,
     .apexcharts-legend .apexcharts-hidden-null-series {
          display: none !important;
     }

     .inactive-legend {
          opacity: 0.45;
     }
</style>
<script>
     import '../plugins/apex/apexcharts.css';
     import '../assets/css/dashboard/dash_2.css';
     export default {
          name: 'dashboard',
          components: {},
          data() {
               return {
                    email: '',
                    password: '',
               };
          },
     };
</script>
